import "./App.css";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import About from "./pages/About";
import Products from "./pages/Products";
import Clients from "./pages/Clinents";
import Contact from "./pages/Contact";
import { BrowserRouter, Routes, Route } from "react-router-dom";
function App() {
  return (
    <div className="App">
      {
        <>
          <Navbar />

          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/About" element={<About />} />
              <Route path="/Products" element={<Products />} />
              <Route path="/Clients" element={<Clients />} />
              <Route path="/Contact" element={<Contact />} />
            </Routes>
          </BrowserRouter>
          <Footer />
        </>
      }
    </div>
  );
}

export default App;
