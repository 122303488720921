import React from "react";
import "./prodects.css";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import data from "../data/ProductsData.json";

function Products() {
  return (
    <div>
      <div className="container products text-center">
        <div className="row text-center pt-5 pb-5">
          <h1 className="product-h1">Products</h1>
        </div>
        <div className="row products-row">
          {data.map((data, id) => (
            <div key={id} className="col-md-3 col-sm-6 product-card">
              <div>
                <img
                  className="products-img"
                  src={data.Images}
                  alt="products-img"
                />
              </div>

              <h4 className="product-cat">{data.Categories}</h4>
              <h2 className="product-name">{data.Name}</h2>
              <a className="product-icon">
                <FontAwesomeIcon icon={faEye} />
              </a>
            </div>
          ))}
        </div>
        <button className="d-btn btn dark-btn">See More</button>
      </div>
    </div>
  );
}

export default Products;
