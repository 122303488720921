// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Breadcramp {
  height: 70vh;
  padding: 150px;
  background-size: cover;
  margin-bottom: 50px;
}

h1 {
  color: #fff;
  font-size: 43px;
  line-height: 1.2;
  text-transform: uppercase;
}
.white-btn {
  position: relative;
  top: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/Breadcramp.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,cAAc;EACd,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,eAAe;EACf,gBAAgB;EAChB,yBAAyB;AAC3B;AACA;EACE,kBAAkB;EAClB,MAAM;AACR","sourcesContent":[".Breadcramp {\r\n  height: 70vh;\r\n  padding: 150px;\r\n  background-size: cover;\r\n  margin-bottom: 50px;\r\n}\r\n\r\nh1 {\r\n  color: #fff;\r\n  font-size: 43px;\r\n  line-height: 1.2;\r\n  text-transform: uppercase;\r\n}\r\n.white-btn {\r\n  position: relative;\r\n  top: 0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
