import React from "react";
import Products from "../components/products";
import Breadcramp from "../components/Breadcramp";
function ProductsPage() {
  return (
    <div>
      <>
        <Breadcramp />
        <Products />
      </>
    </div>
  );
}

export default ProductsPage;
