import React from "react";
import Footerbg from "../images/Footer.jpg";
import "./Footer.css";
import Logo from "../images/logo.png";
import {
  faFacebook,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faEnvelope,
  faAnglesRight,
} from "@fortawesome/free-solid-svg-icons";
import WaveSvg from "./wave";

function Footer() {
  return (
    <div
      className="container-fulid Footer"
      style={{ backgroundImage: `url(${Footerbg})` }}
    >
      <div className="container-svg">
        <WaveSvg />
      </div>
      <div className="container">
        <div className="row ">
          <div className="col-md-4 text-center">
            <img className="footer-logo" src={Logo} alt="Logo" />
            <ul className="Footer-ul-Social  justify-content-center">
              <a href="https://www.facebook.com/alhamadbakehouse/">
                <li>
                  <FontAwesomeIcon className="Footer-icon" icon={faFacebook} />
                </li>
              </a>

              <li>
                <FontAwesomeIcon className="Footer-icon" icon={faTwitter} />
              </li>
              <li>
                <FontAwesomeIcon className="Footer-icon" icon={faInstagram} />
              </li>
            </ul>
          </div>
          <div className="col-md-4">
            <h3>Information</h3>
            <ul className="Footer-ul-contact">
              <li>
                <FontAwesomeIcon className="Page-icon" icon={faAnglesRight} />
                Home
              </li>
              <li>
                <FontAwesomeIcon className="Page-icon" icon={faAnglesRight} />
                About
              </li>
              <li>
                <FontAwesomeIcon className="Page-icon" icon={faAnglesRight} />
                Product
              </li>
              <li>
                <FontAwesomeIcon className="Page-icon" icon={faAnglesRight} />
                Clients
              </li>
              <li>
                <FontAwesomeIcon className="Page-icon" icon={faAnglesRight} />
                Contact Us
              </li>
            </ul>
          </div>
          <div className="col-md-4">
            <h3>Contact Us</h3>
            <ul className="Footer-ul-contact">
              <a href="tel:+201020340444">
                <li>
                  <FontAwesomeIcon className="primery-icon" icon={faPhone} />
                  01020340444
                </li>
              </a>

              <a href="tel:+201281098975">
                <li>
                  <FontAwesomeIcon className="primery-icon" icon={faPhone} />
                  01281098975
                </li>
              </a>

              <a href="tel:+201222547650">
                <li>
                  <FontAwesomeIcon className="primery-icon" icon={faPhone} />
                  01222547650
                </li>
              </a>

              <a href="tel:+201020124333">
                <li>
                  <FontAwesomeIcon className="primery-icon" icon={faPhone} />
                  01020124333
                </li>
              </a>

              <a href="tel:+201004502385">
                <li>
                  <FontAwesomeIcon className="primery-icon" icon={faPhone} />
                  01004502385
                </li>
              </a>

              <a href="mailto:info@Alhamadbakery.com">
                <li>
                  <FontAwesomeIcon className="primery-icon" icon={faEnvelope} />{" "}
                  info@Alhamadbakery.com
                </li>
              </a>
            </ul>
          </div>
        </div>
        <div className="row text-center">
          <p>
            Copyright © All Rights Reserved. 2020-2024 AL Hamad – Design and
            Development Napoleon EG
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
