import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./Clinets.css";
import clients1 from "../images/clients1.png";
import clients2 from "../images/clients2.png";
import clients3 from "../images/clients3.png";
import clients4 from "../images/clients4.png";
import clients5 from "../images/clients5.png";

function Clinets() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <div className="container p-5 text-center">
      <div className="row ">
        <h1 className="Clints-h1">Some Of Our Clients</h1>
        <Carousel
          responsive={responsive}
          showDots={true}
          autoPlaySpeed={1000}
          infinite={true}
        >
          <div className="p-2 clients-slider">
            <img src={clients1} alt="clients1" />
          </div>
          <div>
            <img src={clients2} alt="clients2" />
          </div>
          <div>
            <img src={clients3} alt="clients3" />
          </div>
          <div>
            <img src={clients4} alt="clients4" />
          </div>
          <div>
            <img src={clients5} alt="clients5" />
          </div>
        </Carousel>
        <div className="container">
          <button className="dark-btn btn mt-5">See More</button>
        </div>
      </div>
    </div>
  );
}

export default Clinets;
