import React, { useState } from "react";
import Logo from "../images/logo.png";
import "./Navbar.css";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Navbar() {
  // change color when scroll
  const [color, setColor] = useState(false);
  const changeColor = () => {
    if (window.scrollY >= 90) {
      setColor(true);
    } else {
      setColor(false);
    }
  };

  window.addEventListener("scroll", changeColor);

  return (
    <div className="Navbar-main">
      <>
        <div className="container top-bar  navbar navbar-expand-lg ">
          <div className="col-md-6 col-sm-12 ">
            <ul className="top-bar-ul">
              <li>
                <FontAwesomeIcon className="primery-icon" icon={faPhone} />
                01020340444
              </li>
              <li>
                <FontAwesomeIcon className="primery-icon" icon={faEnvelope} />{" "}
                info@Alhamadbakery.com
              </li>
            </ul>
          </div>
          <div className="col-md-6 m-none">
            <ul className="top-bar-ul  justify-content-end">
              <li>
                <FontAwesomeIcon className="primery-icon" icon={faFacebook} />
              </li>
              <li>
                <FontAwesomeIcon className="primery-icon" icon={faTwitter} />
              </li>
              <li>
                <FontAwesomeIcon className="primery-icon" icon={faInstagram} />
              </li>
            </ul>
          </div>
        </div>

        <nav
          class={
            color
              ? "navbar fixed-top  navbar-expand-lg header-bg"
              : "navbar fixed-top  navbar-expand-lg"
          }
        >
          <div class="container snd-nav">
            <a class="navbar-brand" href="#">
              <img src={Logo} alt="Al-hamed-logo" className="logo" />
            </a>
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div
              class="collapse navbar-collapse justify-content-end"
              id="navbarNav"
            >
              <ul class="navbar-nav">
                <li class="nav-item">
                  <a class="nav-link active" aria-current="page" href="/">
                    HOME
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="/About">
                    ABOUT
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="/Products">
                    PRODUCTS
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="/Clients">
                    CLIENTS
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="/Contact">
                    CONTACT
                  </a>
                </li>
                <li>
                  <button className="btn  white-btn nav-btn">Call Us</button>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </>
    </div>
  );
}

export default Navbar;
