// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--right {
  right: 0;
}
.react-multiple-carousel__arrow--left {
  left: 0;
}
.react-multiple-carousel__arrow {
  background-color: #fff;
}

.react-multiple-carousel__arrow::before {
  font-size: 20px;
  color: #ffcd40;
  display: block;
  font-family: revicons;
  text-align: center;
  z-index: 2;
  position: relative;
}
.Clints-h1 {
  color: #000;
}
button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--right,
button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--left {
  width: 20px;
}
@media only screen and (max-width: 600px) {
  .react-multi-carousel-item {
    text-align: center;
  }

  button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--right,
  button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--left {
    width: 20px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Clinets.css"],"names":[],"mappings":"AAAA;EACE,QAAQ;AACV;AACA;EACE,OAAO;AACT;AACA;EACE,sBAAsB;AACxB;;AAEA;EACE,eAAe;EACf,cAAc;EACd,cAAc;EACd,qBAAqB;EACrB,kBAAkB;EAClB,UAAU;EACV,kBAAkB;AACpB;AACA;EACE,WAAW;AACb;AACA;;EAEE,WAAW;AACb;AACA;EACE;IACE,kBAAkB;EACpB;;EAEA;;IAEE,WAAW;EACb;AACF","sourcesContent":["button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--right {\r\n  right: 0;\r\n}\r\n.react-multiple-carousel__arrow--left {\r\n  left: 0;\r\n}\r\n.react-multiple-carousel__arrow {\r\n  background-color: #fff;\r\n}\r\n\r\n.react-multiple-carousel__arrow::before {\r\n  font-size: 20px;\r\n  color: #ffcd40;\r\n  display: block;\r\n  font-family: revicons;\r\n  text-align: center;\r\n  z-index: 2;\r\n  position: relative;\r\n}\r\n.Clints-h1 {\r\n  color: #000;\r\n}\r\nbutton.react-multiple-carousel__arrow.react-multiple-carousel__arrow--right,\r\nbutton.react-multiple-carousel__arrow.react-multiple-carousel__arrow--left {\r\n  width: 20px;\r\n}\r\n@media only screen and (max-width: 600px) {\r\n  .react-multi-carousel-item {\r\n    text-align: center;\r\n  }\r\n\r\n  button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--right,\r\n  button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--left {\r\n    width: 20px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
