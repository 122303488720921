import React from "react";
import "./About.css";
import about from "../images/about.png";
import "../App.css";

function About() {
  return (
    <div>
      <>
        <div className="container about mt-5">
          <div className="row">
            <div className="col-md-6">
              <img className="About-img" src={about} alt="about" />
            </div>
            <div className="col-md-6">
              <h2 className="About-Title">Welcome To Al Hamad</h2>
              <p className="About-des">
                Al Hamad Company was established in 2007
                <br /> is a leading company in the manufacture and distribution
                of baked goods throughout the Republic. We deal with the largest
                restaurants and fast food outlets. We use the finest materials
                in manufacturing and the highest technology in the bakery
                industry. <br />
                All our suppliers a white list on the National Food Safety
                Authority
                <br /> Simply Al Hamd is the best
              </p>
              <button className="dark-btn btn ">See More</button>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default About;
